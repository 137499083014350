// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ablauf-js": () => import("./../../../src/pages/ablauf.js" /* webpackChunkName: "component---src-pages-ablauf-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-antje-muetzel-js": () => import("./../../../src/pages/antje-muetzel.js" /* webpackChunkName: "component---src-pages-antje-muetzel-js" */),
  "component---src-pages-behandlungsspektrum-js": () => import("./../../../src/pages/behandlungsspektrum.js" /* webpackChunkName: "component---src-pages-behandlungsspektrum-js" */),
  "component---src-pages-coaching-js": () => import("./../../../src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kognitive-verhaltenstherapie-js": () => import("./../../../src/pages/kognitive-verhaltenstherapie.js" /* webpackChunkName: "component---src-pages-kognitive-verhaltenstherapie-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kosten-js": () => import("./../../../src/pages/kosten.js" /* webpackChunkName: "component---src-pages-kosten-js" */),
  "component---src-pages-paartherapie-js": () => import("./../../../src/pages/paartherapie.js" /* webpackChunkName: "component---src-pages-paartherapie-js" */),
  "component---src-pages-weitere-therapieansaetze-js": () => import("./../../../src/pages/weitere-therapieansaetze.js" /* webpackChunkName: "component---src-pages-weitere-therapieansaetze-js" */)
}

